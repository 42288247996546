export const Spanish = {
  //herosection
  heroTitle: "Un instituto de idiomas profesional, personalizado y flexible",
  heroSubtitle:"¿Te gustaría aprender un idioma? Crea tu propio curso personalizado con nosotros y aprende con un profesor nativo.",
  heroSubtitletwo:"Ofrecemos clases en estos idiomas: Inglés, Francés, Alemán, Portugués, Italiano, Chino, Español para extranjeros",
  buttonMoreInfo: "Más info",

  //herosectionMobile
  heroTitleMobile: "Instituto de idiomas profesional y flexible",
  heroSubtitleMobile: "Aprendé un idioma con un curso personalizado y profesor nativo.",
  heroSubtitletwoMobile: "Idiomas: Inglés, Francés, Alemán, Portugués, Italiano, Chino y Español.",
  buttonMoreInfoMobile: "Más info",

  //navbar
  navAboutus: "Acerca de nosotros",
  navClasses: "Clases",
  navTranslations: "Traducciones",
  navGroups: "Grupos",
  navPrices: "Paquetes",
  navReviews: "Opiniones",
  navContact: "Contacto",
  navLanguage: "Idioma",

  //aboutus
  aboutusTitle: "Aprende un nuevo idioma desde cualquier parte del mundo",
  aboutusParagraphOne:"Robinson’s Institute of Languages fue fundado en 2011 con la intención de ofrecer clases de idiomas para personas que buscan aprender de manera personalizada.",
  aboutusParagraphTwo:"Nos especializamos en clases para estudiantes que quieran dar exámenes, ir de viaje, hacer negocios o solo aprender un idioma en general.",
  aboutusParagraphThree:"Todos los profesores del instituto son expertos en el idioma y cuentan con cualificaciones docentes y experiencia impartiendo clases.",
  aboutusParagraphFour: "El departamento de traducciones se abrió en 2013 y consiste de ambos traductores públicos argentinos y traductores nativos del inglés para lograr mejores traducciones. Trabajamos en equipo para poder traducir largos textos en menos tiempo sin perder la calidad de la traducción.",

  //aboutusMobile
  aboutusTitleMobile: "Aprendé un nuevo idioma desde cualquier lugar",
  aboutusParagraphOneMobile: "Desde 2011, el Instituto Robinson ofrece clases personalizadas.",
  aboutusParagraphThreeMobile: "Profesores expertos, con experiencia y certificación.",
  


  // classes
  classesTitle: "Logra más con RIL",
  classesVirtual: "Clases virtuales",
  classesParagraphOne:"En Robinson’s Institute of Languages, comprendemos la importancia de la conversación al aprender un nuevo idioma, ya que es la base de nuestro método de enseñanza. Todos nuestros profesores cuentan con certificados internacionales y años de experiencia. Somos expertos en evaluar el nivel y las necesidades de cada estudiante para poder planificar un curso personalizado.",
  classesGroupAndIndividual: "Clases individuales y grupales",
  classesParagraph:"Desbloquea el poder de tu camino personalizado de aprendizaje para lograr tus objetivo de idioma rapido. ¡Descubre cuán fácil y divertido puede ser aprender un nuevo idioma con Robinson Institute of Language!",
  classesParagraphTwo: "Buscamos crear clases dinámicas e interactivas. Usamos diferentes herramientas, como el internet, para hacer de las clases más divertidas y completas. Contamos con material gramatical, actividades de escuchar y leer y para preparación de exámenes.",
  classesParagraphThree:"Ofrecemos flexibilidad en cuanto al horario y lugar donde se toman las clases. Se puede tomar clases individuales o en grupo.",

  // classesMobile
  classesTitleMobile: "Lográ más con RIL",
  classesVirtualMobile: "Clases virtuales",
  classesGroupAndIndividualMobile: "Individual y grupal",
  classesParagraphOneMobile: "La conversación es clave en Robinson. Nuestros profesores certificados adaptan los cursos al nivel y objetivos de cada estudiante.",
  classesParagraphMobile: "Alcanzá tus metas con un aprendizaje personalizado. ¡Aprender en Robinson es fácil y divertido!",
  classesParagraphTwoMobile: "Clases dinámicas e interactivas con herramientas en línea, gramática, comprensión auditiva, lectura y preparación para exámenes.",
  classesParagraphThreeMobile: "Opciones flexibles: individual o grupal, elegí tu horario y lugar.",

  //translationMobile
  translationButtonMobile: "Ver más",
  translationsParagraphOneMobile: "Traducción e interpretación profesional con traductores certificados en Argentina y nativos del Reino Unido y EE.UU.",


  //translations
  translationsTitle: "Servicios de traducciones",
  translationButton:"Ver más sobre nuestros servicios de traducciones",
  translationOrangeBgTitle: "¿Tienes más preguntas?",
  translationOrangeBgSubtitle: "¡Contactanos!",
  translationOrangeBgParagraphOne: "Nuestro equipo está preparado para encontrar una solución a",
  translationOrangeBgParagraphTwo: "tus necesidades de traducción",
  translationOrangeBgButton: "Contactános",
  translationsParagraphOne:
    "Nuestros servicios de traducción e interpretación son profesionales. Contamos con traductores certificados en Argentina y traductores nativos del Reino Unido y Estados Unidos de América.",

  //translationPublic
  translationsPublic: "Traducciones Públicas",
  translationsPublicBold: "Por documentos como:",
  translationsPublicListOne: "Certificados de nacimiento, defunción, matrimonio y divorcio",
  translationsPublicListTwo: "Pasaportes y demás documentos personales",
  translationsPublicListThree: "Testamentos",
  translationsPublicListFour:"Programas de estudios, certificados analíticos, diplomas, CV, y demás documentos relacionados con la educación",
  translationsPublicListFive: "Poderes",
  translationsPublicListSix:"Documentos Societarios (actas constitutivas, estatutos societarios, actas de asamblea y de directorio, documentos comerciales, y otros documentos societarios)",
  translationsPublicListSeven: "Documentos técnicos y científicos",
  translationsPublicListEight:"Patentes de invención, propiedad intelectual, derechos de autor",
  translationsPublicListNine: "Estados contables",

  //translationPublicMobile
  translationsPublicMobile: "Traducciones Públicas",
  translationsPublicBoldMobile: "Para documentos como:",
  translationsPublicListOneMobile: "Certificados de nacimiento, matrimonio y divorcio",
  translationsPublicListTwoMobile: "Pasaportes e identificación personal",
  translationsPublicListThreeMobile: "Testamentos",
  translationsPublicListFourMobile: "Documentos educativos (diplomas, CVs)",
  translationsPublicListFiveMobile: "Contratos",
  translationsPublicListSixMobile: "Documentos corporativos (estatutos, actas)",
  translationsPublicListSevenMobile: "Documentos técnicos/científicos",
  translationsPublicListEightMobile: "Patentes, propiedad intelectual, derechos de autor",
  translationsPublicListNineMobile: "Estados financieros",


  //translationPrivate
  translationsPrivate: "Traducciones Privadas",
  translationsPrivateBold: "Por documentos en las áreas de:",
  translationsPrivateListOne: "Economía y Finanzas",
  translationsPrivateListTwo: "Comerciales",
  translationsPrivateListThree: "Técnicas",
  translationsPrivateListFour: "Literarias",
  translationsPrivateListFive: "Artículos periodísticos",
  translationsPrivateListSix: "Tesis",
  translationsPrivateListSeven: "Presentaciones",

  //translationInterpretation
  translationsInterpretation: "Interpretación",
  translationsInterpretationBold: "Para dar asistencia idiomática:",
  translationsInterpretationListOne: "En congresos y conferencias",
  translationsInterpretationListTwo: "En reuniones de negocios",
  translationsInterpretationListThree: "A visitantes extranjeros",

  //groups
  groupsTitle: "Grupos",
  groupsButton: "Buscar un grupo",
  

    //prices
    pricesError: "por favor, seleccione 1 mes o 3 meses",
    pricesTitle: "Mira nuestros planes",
    starterTitle: "Starter",
    starterSubtitle: "1 hora por semana",
    starterSubtitleTwo: "1 clase por semana",
    starterText: "Mantiene contacto con el idioma, evite perder su nivel",
    starterPriceDollarsMonth: "USD $60",
    starterPricePesosMonth: "ARS $40.000",
    starterPriceDollarsMonthGroup: "USD $32",
    starterPricePesosMonthGroup: "ARS $21.800",
    starterPriceDollarsThreeMonths: "USD $162",
    starterPricePesosThreeMonths: "ARS $108.000",
    starterPriceDollarsThreeMonthsGroup: "USD $86.4",
    starterPricePesosThreeMonthsGroup: "ARS $56.700",
    semiProTitle: "Semi-pro",
    semiProSubtitle: "2 horas por semana",
    semiProSubtitleTwo: "2 clases por semana",
    semiProPriceDollarsMonth: "USD $116",
    semiProPricePesosMonth: "ARS $78.400",
    semiProPriceDollarsMonthGroup: "USD $60",
    semiProPricePesosMonthGroup: "ARS $41.200",
    semiProPriceDollarsThreeMonths: "USD $312.2",
    semiProPricePesosThreeMonths: "ARS $211.680",
    semiProPriceDollarsThreeMonthsGroup: "USD $162",
    semiProPricePesosThreeMonthsGroup: "ARS $111.240",
    semiProText: "Mejore su nivel del idioma aprendiendo nueva gramática y vocabulario",
    proTitle: "Pro",
    proSubtitle: "3 horas por semana",
    proSubtitleTwo: "3 clases por semana",
    proPriceDollarsMonth: "USD $168",
    proPricePesosMonth: "ARS $115.200",
    proPriceDollarsMonthGroup: "USD $84",
    proPricePesosMonthGroup: "ARS $60.600",
    proPriceDollarsThreeMonths: "USD $453.6",
    proPricePesosThreeMonths: "ARS $311.040",
    proPriceDollarsThreeMonthsGroup: "USD $226.8",
    proPricePesosThreeMonthsGroup: "ARS $163.620",
    proText: "Curso intensivo, usualmente por algo específico, cumpla sus objetivos en un plazo más corto",
    pricesButtonIndividual: "Clases individuales",
    pricesButtonGroup: "Buscar Grupo",
    pricesRenewMonth: "Se renueva todos los meses",
    pricesRenewThreeMonths: "Se renueva todos los años",
    pricesMonth: "1 mes",
    pricesThreeMonths: "3 meses",
    //reviews
    reviewsTitle: "Algunos de nuestros clientes felices",
    companiesThatTrustUs: "Empresas que confían en nosotros",

    //contact
    contactTitle: "Contacto",
    contactName: "Nombre",
    contactEmail: "Email",
    contactTelephone: "Teléfono",
    contactMessage: "Mensaje",
    contactButton: "Enviar",
    placeholderName: "Ingrese su nombre",
    placeholderEmail: "Ingrese su email",
    placeholderTelephone: "Ingrese su teléfono",
    placeholderMessage: "Ingrese su mensaje",


    //form
    formtitle: "Buscar un grupo",
    formtitleIndividual: "Clases individuales",
    modalSuccessForm: "Gracias por llenar su formulario! Uno de nuestro equipo va a estar en contacto pronto con usted.",
    modalWarningGroup: "Ha seleccionado menos de tres horarios. ¿Estás seguro de que quieres continuar?",
    modalWarningIndividual: "Ha seleccionado menos de tres horarios. ¿Estás seguro de que quieres continuar?",
    modalWarningButton: "Sí, estoy seguro",
    modalWarningButtonCancel: "No, quiero seleccionar más horarios",
    modalWarningTitle: "¡Atención!",
    modalSuccess: "Formulario enviado con éxito!",
    formAreSureYouWantToLeave: "¿Estás seguro que quieres salir? Perderás todos los datos que hayas ingresado.",

    //general
    generalBlueButton: "¡Empieza tu programa hoy!",
    placeholderFirstName: "Ingrese su nombre",
    placeholderLastName: "Ingrese su apellido",
    placeholderSelectLanguage: "Seleccione un idioma",
    placeholderSelectLevel: "Seleccione un nivel",
    placeholderSelectObjective: "Seleccione un objetivo",
    placeholderPreferedSchedule: "Seleccione un horario",
    placeholderSelectDay: "Seleccione un día",
    placeholderSelectTime: "Seleccione un horario",
    placeholderExam: "Seleccione un examen",
    placeholderTimeZone: "Seleccione una zona horaria",
    placeholderListPreferedSchedule: "Agregue un horario acá",
    examOtherPleaseSpecify: "Especifique examen",
    buttonSubmit: "Enviar",
    buttonAdd: "Agregar",
    errorFirstName: "Por favor ingrese su nombre",
    errorLastName: "Por favor ingrese su apellido",
    errorTimeZone: "Por favor seleccione primero una zona horaria y luego un horario",
    errorDayorTime: "Por favor seleccione un dia y un horario preferido",
    errorEmail: "Por favor ingrese un email valido",
    errorTelephone: "Por favor ingrese un teléfono valido",
    errorLanguage: "Por favor seleccione un idioma",
    errorLevel: "Por favor seleccione un nivel",
    errorObjective: "Por favor seleccione un objetivo",
    errorSchedule: "Por favor seleccione un horario",
    errorDay: "Por favor seleccione un día",
    errorTelephoneLenght: "Por favor ingrese un teléfono válido",
    errorTime: "Por favor seleccione un horario",
    errorExam: "Por favor seleccione un examen",
    errorFormMessage: "Por favor complete el formulario entero",
    errorSpecifyExam: "Por favor especifique el examen",
    errorInvalidTime: "Por favor seleccione un horario válido",
    errorFormGeneral: "Por favor complete el formulario, que todos los campos estén completos y seleccionados correctamente, y que haya eligido al menos tres horarios",
    placeholderExamSpecific: "Cual examen?",
    alertyoullbecontacted: "Gracias por contactarnos, nos pondremos en contacto con usted a la brevedad",
    buttonClose: "Cerrar",
    alertMessageSent: "Mensaje enviado",

    //languages
    english: "Inglés",
    spanish: "Español para extranjeros",
    french: "Francés",
    german: "Alemán",
    italian: "Italiano",
    portuguese: "Portugués",
    russian: "Ruso",


    //levels
    beginner: "Principiante",
    preIntermediate: "Pre-intermedio",
    intermediate: "Intermedio",
    upperIntermediate: "Superior-intermedio",
    advanced: "Avanzado",

    //objectives
    conversation: "Conversación",
    exam: "Preparación de exámenes",
    business: "Negocios",

    //days
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
    sunday: "Domingo",

     //footer:
     footerAbout : "Acerca de nosotros",
     footerContact: "Contacto",
     footerFollowUs: "Síguenos",
     footerLanguageCourses: "Cursos de idiomas",
     footerGroupClasses: "Clases en grupo",
     footerTranslationService: "Servicio de traducción",
     footerPackages: "Paquetes",
     footerReviews: "Opiniones"


};
